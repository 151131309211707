.FillSelect__list {
  padding-left: 0;
}

ul > li::before {
  content: none;
}

.FillSelect__item {
  margin: 1rem 0;
  padding: 1em;
  background-color: var(--gray-color);
  border-radius: 0.2em;
}

.FillSelect__example {
  text-transform: none;
  font-family: "my-loaded-font";
  font-weight: normal;
  letter-spacing: 0;
  font-size: 2rem;
  line-height: 1.3;
  margin: 0;
  padding: 0;
}

.FillSelect__example + .FillSelect__example {
  margin-bottom: 1rem;
}

.FillSelect__example::first-letter {
  font-size: 1rem;
}

.FillSelect__spaceMock {
  display: inline-block;
}
