.App {
  margin: 2rem auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 38rem;
}

button {
  display: block;
  border: none;
  background-color: var(--blue-color);
  border-radius: 0.2em;

  margin: 1rem 0;
  padding: 1em;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-weight: inherit;
}

button:hover {
  background-color: var(--green-color);
}
/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
